import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { lineElementClasses, markElementClasses, axisClasses } from '@mui/x-charts';

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1900,
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#324eef',
        },
        secondary: {
            main: '#2e2e2e',
        },
        header: {
            main: '#234',
        },
        card: {
            main: grey[200],
        },
        text: {
            light: grey[800],
            dark: 'black',
        },
        tan: {
            main: '#c3bcb6',
        },
        background: {
            dark: grey[300],
        },
        gradient: {
            main: 'linear-gradient(90deg, hsla(22, 100%, 78%, 1) 0%, hsla(2, 78%, 62%, 1) 100%)',
        },

    },
    typography: {
        fontFamily: 'Poppins',
        h1: {
            fontSize: '4rem',
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '2rem',
            fontWeight: 300,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 600,
        },

        body1: {
            fontWeight: 300,
            fontSize: '1rem',
            letterSpacing: '.03em',
        },
        body2: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        body3: {
            fontWeight: 600,
            fontSize: '1.2rem',
            color: grey[600],
        },
    },
    shape: {
        borderRadius: 28,
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                backdrop: {
                    backdropFilter: 'blur(5px)',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'primary.dark',
                    },
                    boxShadow: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                        borderColor: 'linear-gradient(90deg, hsla(244, 100%, 60%, 1) 0%, hsla(27, 100%, 79%, 1) 100%)',
                    },
                },
            },
        },


    },
});

theme = responsiveFontSizes(theme);

export { theme };
