import React, { lazy, Suspense, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import { theme } from './Theme';
import Header from './components/navbar/Header';
import Footer from './components/footer/Footer';
import CartProvider from './components/navbar/CartContext';
import ScrollToTop from './hooks/ScrollToTop';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useGSAP } from '@gsap/react';
import NotFound from './pages/NotFound';
import UseSVH from './hooks/UseSVH';
import ReactGA from 'react-ga4';
import usePageTracking from './hooks/UsePageTracking';
import { Box, CircularProgress, Typography, Fade } from '@mui/material';


gsap.registerPlugin(ScrollTrigger, useGSAP);


// Lazy load pages
const Home = lazy(() => import('./pages/Home'));

const Products = lazy(() => import('./pages/products/Products'));
const Shop = lazy(() => import('./pages/products/Shop'));
const Adapter = lazy(() => import('./pages/products/Adapter'));
const Chiller = lazy(() => import('./pages/products/Chiller'));
const Tub = lazy(() => import('./pages/products/Tub'));
const PurchaseSuccess = lazy(() => import('./pages/products/PurchaseSuccess'));

const Learn = lazy(() => import('./pages/learn/Learn'));
const FAQ = lazy(() => import('./pages/learn/FAQ'));
const Maintenance = lazy(() => import('./pages/learn/Maintenance'));
const Setup = lazy(() => import('./pages/learn/Setup'));
const AdapterSetup = lazy(() => import('./pages/learn/AdapterSetup'));
const Handpump = lazy(() => import('./pages/learn/Handpump'));
const Priming = lazy(() => import('./pages/learn/Priming'));
const Controls = lazy(() => import('./pages/learn/Controls'));
const FiltrationSetup = lazy(() => import('./pages/learn/FiltrationSetup'));
const InflateSetup = lazy(() => import('./pages/learn/InflateSetup'));
const Troubleshooting = lazy(() => import('./pages/learn/Troubleshooting'));

const Support = lazy(() => import('./pages/support/Support'));
const About = lazy(() => import('./pages/support/About'));
const Contact = lazy(() => import('./pages/support/Contact'));
const Policies = lazy(() => import('./pages/support/Policies'));
const RefundPolicy = lazy(() => import('./pages/support/RefundPolicy'));
const WarrantyPolicy = lazy(() => import('./pages/support/WarrantyPolicy'));
const PrivacyPolicy = lazy(() => import('./pages/support/PrivacyPolicy'));


function App() {
  UseSVH();




  useEffect(() => {

    ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID, { debug_mode: false });
    // Capture fbclid from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');

    // Store fbclid in local storage if it's found in the URL
    if (fbclid) {
      localStorage.setItem('fbclid', fbclid);
    }

    // Capture _fbp from cookies if it exists
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const fbp = getCookie('_fbp');
    if (fbp) {
      localStorage.setItem('_fbp', fbp);
    }
  }, []);



  return (

    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CartProvider>

          <ScrollToTop />

          <Header />
          <Suspense fallback={<Loading />}>
            <Routes>
              usePageTracking();

              <Route path='/' element={<Home />} />
              <Route path='/products' element={<Products />} />
              <Route path='/shop' element={<Shop />} />
              <Route path='/learn' element={<Learn />} />
              <Route path='/learn/faq' element={<FAQ />} />
              <Route path='/learn/maintenance' element={<Maintenance />} />
              <Route path='/learn/setup/' element={<Setup />} />
              <Route path='/learn/setup/adapter' element={<AdapterSetup />} />
              <Route path='/learn/setup/priming-handpump' element={<Handpump />} />
              <Route path='/learn/setup/priming' element={<Priming />} />
              <Route path='/learn/setup/controls' element={<Controls />} />
              <Route path='/learn/setup/filtration' element={<FiltrationSetup />} />
              <Route path='/learn/setup/inflate' element={<InflateSetup />} />
              <Route path='/learn/troubleshooting' element={<Troubleshooting />} />
              <Route path='/support' element={<Support />} />
              <Route path='/support/about' element={<About />} />
              <Route path='/support/contact' element={<Contact />} />
              <Route path='/support/policies' element={<Policies />} />
              <Route path='/support/policies/refund' element={<RefundPolicy />} />
              <Route path='/support/policies/warranty' element={<WarrantyPolicy />} />
              <Route path='/support/policies/privacy' element={<PrivacyPolicy />} />
              <Route path='/success' element={<PurchaseSuccess />} />
              <Route path="*" element={<NotFound />} />

            </Routes>
          </Suspense>
          <Footer />


        </CartProvider>

      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;



const Loading = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 150);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fade
      in={show}
      timeout={{
        enter: 300,
        exit: 200,
      }}
      unmountOnExit
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(var(--lvh) * 10)',
          gap: 2,
        }}
      >
        <CircularProgress size={40} />
        <Typography variant="h6" color="text.secondary">
          Loading...
        </Typography>
      </Box>
    </Fade>
  );
};
