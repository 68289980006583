import * as React from 'react';
import {
    Typography,
    Box, Avatar,
    Stack
} from '@mui/material';

import { indigo } from '@mui/material/colors';


const avaColor = '#32eef55'
const iconSize = { width: '4rem', height: '4rem' };

export const ProductIcon = ({includes}) => {
    // Check if includes exists and is an array
    if (!includes || !Array.isArray(includes)) {
        console.warn('ProductIcon: includes prop is not an array', includes);
        return null;
    }

    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
            {includes.map((item, idx) => (
                <Stack            key={idx}  direction={'column'} alignItems={'center'} spacing={1}>
                    <Box 
             
                    component='img' 
                    src={item.src} 
                    alt={item.title} 
                    sx={{
                        bgcolor: avaColor,
                        borderRadius: '50%',
                        width: '4rem',
                        height: '4rem',
                        padding: '0.5rem',
                        objectFit: 'cover',
                        objectPosition: 'center'
                        }}
                    />
                    <Typography variant='button' pt={1}>{item.title}</Typography>
                </Stack>
            ))}
        </Box>
    );
}


