import React, { useContext } from 'react';
import { Button, Paper, Grow, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getProductData, productsArray } from '../data';
import { CartContext } from './CartContext';

function ProductsDropdown(props) {
    const { color, url, textTransform } = props;

    const [hover, setHover] = React.useState(false);

    return (
        <div className="dropdown"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ position: 'relative', display: 'inline-block' }}>

            <Button to={url} component={Link} endIcon={<ArrowDropDownIcon />} sx={{ color: color, textTransform: textTransform }} >
                Products
            </Button>

            <Grow in={hover} >
                <Paper 
                    sx={{
                        position: 'absolute',
                        backgroundColor: '#f1f1f1',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: "column",
                        maxWidth: '1000px',
                        width: { xs: '80vw', md: '50vw' },
                        padding: 1,
                    }}
                >
                    <Typography variant='h3' fontSize={'1.5rem'} fontWeight={600} sx={{ padding: 2 }}>Cold Plunges</Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, width: '100%', }}>
                        {productsArray.slice(0, 3).map((item, idx) => (
                            <Box m={1} key={idx}>
                                <MenuProducts item={item} setHover={setHover} chiller='pro' tub={idx === 1 ? 'recline' : 'barrel'} />
                            </Box>
                        ))}
                    </Box>
                    <Button
                        variant='outlined'
                        color='primary'
                        to={'/products'}
                        component={Link}
                        onClick={() => setHover(false)}
                        sx={{
                            marginY: 2,
                            marginX: 2,
                            padding: '.25rem',
                            textTransform: 'none',
                            minWidth: 'auto',
                            display: 'inline',
                            maxWidth: '6rem',
                            textAlign: 'center'
                        }}
                    >
                        View All
                    </Button>
                </Paper>
            </Grow>
        </div>
    );
}

const MenuProducts = ({ item, setHover, chiller, tub }) => {
    const { productImages, category, price } = getProductData(item.id, chiller, tub);
    const { setSelectedItem, setSelectedAttributes } = useContext(CartContext);

    return (
        <Box
            component={Link}
            to="/shop"
            onClick={() => {
                setHover(false);
                setSelectedItem(item);
                setSelectedAttributes({ chiller: chiller, tub: tub });
            }}
            sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                textDecoration: 'none',
                color: 'inherit',
                gap: 2,
                borderRadius: '28px',
                padding: 1,
                
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
            }}
        >
            <Box
                component="img"
                src={`${productImages[0]}-md.webp`}
                alt={category}
                sx={{
                    width: { xs: '30%', md: '100%' },
                    height: { xs: 'auto', md: '100%' },
                    objectFit: 'contain'
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant="subtitle1" fontWeight={500} mb={{ xs: .5, md: 1 }}>
                    {category}
                </Typography>
                <Typography variant="subtitle2" fontWeight={400} color="text.secondary" mb={{ xs: .5, md: 1 }}>
                    {item.text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    ${typeof price === 'number' ? price : 'N/A'} {/* Default to 'N/A' if price is not a number */}
                </Typography>
            </Box>
        </Box>
    );
};

export default ProductsDropdown;
