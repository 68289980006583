export const reviewArray = [
    {
        name: 'Ben Hunt',
        images: ['/images/reviewImages/benH1.webp'],
        rating: 5,
        review: 'This system is incredibly high quality. It is nicer than many others I considered purchasing. The chiller keeps my water cold even in the Arizona heat. Very satisfied!!',
        purchased: 'AZ-Inflate',
        timestamp: '9/18/2024 19:51:50'
    }, {
        name: 'Devin Spera',
        images: ['/images/reviewImages/devinS1.webp', '/images/reviewImages/devinS2.webp'],
        rating: 5,
        review: "The set up was easy. The system appears to be efficient and is very effective. The adapter worked for the tub I had. I upgraded to a large cooler and no longer use it, however. I recommend this chiller to anyone who will listen.",
        purchased: 'AZ-Adapt',
        timestamp: '10/28/2024 17:25:01'
    }, {
        name: 'Savannah Sen',
        images: ['/images/reviewImages/savannahS1.webp'],
        rating: 5,
        review: "Cold plunging has been awesome, really keeps my motivation up afterward, helps the rest of the day flow. The tub has been really great, way sturdier than it looks, chiller is good, I keep mine around 39 no problem.",
        purchased: 'AZ-Inflate',
        timestamp: '9/18/2024 15:52:20'
    }, {
        name: 'Krishna A',
        images: ['/images/reviewImages/krishnaA1.webp', '/images/reviewImages/krishnaA2.webp'],
        rating: 5,
        review: "Setup was easy with clear directions from website. Communication was smooth from Ethan and shipping was fast. System cools down water very quickly. I wish power cord was longer since I don't have any outlet nearby. (I am using an extension).",
        purchased: 'AZ-Adapt',
        timestamp: '8/5/2024 23:42:14'
    }, {
        name: 'Jeremy Block',
        images: ['/images/reviewImages/jeremyB1.webp'],
        rating: 5,
        review: "This is a great machine and does what it's supposed to do…gets you bathtub water cold! The chiller does make some noise, just fyi if you have it next to your bedroom. Also if you're using it indoors, keep in mind there will be some dripping of water onto the floor, not from the chiller itself, but from the hoses and tub itself as a result of condensation. The setup looks way more daunting than it actually is. I am not very technical or handy, but was able to get everything working well including the filtration system. Ethan and the AZ team were incredibly helpful and responsive with any questions I had. Amazing customer service. I highly recommend this brand and these products!",
        purchased: 'AZ-Adapt',
        timestamp: '8/15/2024 15:31:23'
    }, {
        name: 'Brian A',
        images: ['/images/reviewImages/brianA1.mp4'],
        rating: 5,
        review: "High quality parts, all necessary items for an exceptional plunge experience are included, powerful, yet quiet chiller. Awesome design. A great company to do business with!! Highly recommend to anyone looking for a cold plunge without the hassles of ice.",
        purchased: 'AZ-Adapt',
        timestamp: '8/27/2024 13:42:45'
    }, {
        name: 'Kyle Smith',
        images: ['/images/reviewImages/kyleS1.webp'],
        rating: 5,
        review: "I love being able to ditch the ice and an hour routine to plunge. Now you set the temp and forget about it and as long as I have 10 minutes I can get in. No more excuses. It is louder than I thought it would be but that's been the only negative. I might need to find a different chiller though since I live in MN and it will freeze, if I had one that cooled and heated water I would be ok since my only space is outdoors.",
        purchased: 'Chiller',
        timestamp: '9/18/2024 20:15:55'
    }, {
        name: 'Tim A',
        images: ['/images/reviewImages/TimA1.webp'],
        rating: 5,
        review: 'Very simple to use and works like a charm.',
        purchased: 'Chiller',
        timestamp: '9/18/2024 21:31:48'
    }, {
        name: 'Gary Michelson',
        images: ['/images/reviewImages/garyM1.webp'],
        rating: 4,
        review: "Good chiller, compact, efficient and well built, but can be a little noisy. Really like the two-filter system (UV and cartridge). And, I like the digital thermostat. Instead of the dash lines in the readout, however, it could say pump so you know just the pump is running (as it seems so quiet when just the pump is running). The accompanying literature could be improved with pictorial representations. The internal fan mounting could be improved to increase the clearance between the blades and stand (to prevent interference, like I experienced). Also, information could be included to indicate how often the cartridge filter should be changed and with what type of filter.",
        purchased: 'Chiller',
        timestamp: '9/29/2024 15:19:15'
    }, {
        name: 'Mark Q.',
        images: [],
        rating: 5,
        review: 'AZ-Complete kit is fantastic. Tub and chiller are both high quality, super glad I went for it.',
        purchased: 'AZ-Complete',
        timestamp: '10/6/2024 19:15'
    }, {
        name: 'Emily M.',
        images: [],
        rating: 5,
        review: '',
        purchased: 'AZ-Adapt',
        timestamp: '10/5/2024 23:42'
    }, {
        name: 'Larry W.',
        images: [],
        rating: 5,
        review: "Does exactly what it's supposed to do. The water stays cold, and I don't have to worry about constantly refilling it. Good customer support too!!",
        purchased: 'AZ-Inflate',
        timestamp: '9/7/2024 20:13'
    }, {
        name: 'Luke P.',
        images: [],
        rating: 5,
        review: 'All good!',
        purchased: 'AZ-Inflate',
        timestamp: '9/10/2024 3:34'
    }, {
        name: 'Kevin Y.',
        images: [],
        rating: 5,
        review: "It's a little louder than I expected, but it cools FAST, I had a .5hp before that was pretty low quality and never could get that cold, worth the upgrade.",
        purchased: 'AZ-Adapt',
        timestamp: '9/12/2024 13:48'
    }, {
        name: 'Alex F.',
        images: [],
        rating: 4,
        review: "The good: Cools fast, pretty easy to setup, water stays clean, sturdy as hell. The bad: Short power cord, heavy, throws a lot of warm air—you need a window or leave the door open at least. All in all stoked, been loving cold plunging in the morning.",
        purchased: 'AZ-Adapt',
        timestamp: '9/26/2024 14:47'
    }, {
        name: 'John P.',
        images: [],
        rating: 5,
        review: "The chiller is a bit heavy, but once it's in place, it works flawlessly. Setup takes a bit, like 30 mins my first time, but if I did it again I could do it in 10 I think. Cool mission-driven business, I was happy to support!",
        purchased: 'AZ-Adapt',
        timestamp: '9/5/2024 19:15'
    }, {
        name: 'Jacob N.',
        images: [],
        rating: 5,
        review: "I'm in it for the mental benefits, and man it's legit. Start pretty much every day with a plunge now. Still hate it low key but afterward is always worth it.",
        purchased: 'AZ-Inflate',
        timestamp: '10/21/2024 14:42'
    }, {
        name: 'Michael V.',
        images: [],
        rating: 4,
        review: 'Gets my tub to 42 which is a bit higher than advertised, but I guess it varies a bit from tub to tub. Other than that all good, been loving it thank you guys!',
        purchased: 'AZ-Adapt',
        timestamp: '9/27/2024 11:46'
    }, {
        name: 'Harry Q.',
        images: [],
        rating: 5,
        review: "I'm just leaving this review since the threads on my inlet of the adapter stripped out, was sort of bad manufacturing but they honored the warranty and hooked me up with a new one for free. Honest business with good customer service!",
        purchased: 'AZ-Adapt',
        timestamp: '8/29/2024 3:07'
    }, {
        name: 'Sam G.',
        images: [],
        rating: 5,
        review: 'Been working great, love it!',
        purchased: 'AZ-Complete',
        timestamp: '9/20/2024 17:57'
    }, {
        name: 'Chris V.',
        images: [],
        rating: 5,
        review: 'I cooked my pump on accident and got a free replacement, super good customer support!',
        purchased: 'AZ-Adapt',
        timestamp: '10/18/2024 13:37'
    }, {
        name: 'Ethan L.',
        images: [],
        rating: 5,
        review: "This was the best investment I've made for my health in years. Can't recommend it enough.",
        purchased: 'AZ-Adapt',
        timestamp: '9/8/2024 18:44'
    }, {
        name: 'Phillip B.',
        images: [],
        rating: 5,
        review: "Bought the AZ-Inflate thinking it would make me feel invincible. Instead, I now scream every time I step in. My neighbors think I'm either fighting off a wild animal or auditioning for a horror movie. But honestly, 10/10 would recommend.",
        purchased: 'AZ-Inflate',
        timestamp: '8/27/2024 16:16'
    }, {
        name: 'Rachel X.',
        images: [],
        rating: 5,
        review: "I got the AZ-Adapt during their Indiegogo presale. I was honestly nervous when they ended up shipping a month late, but they pulled through and sent an awesome product.",
        purchased: 'AZ-Adapt',
        timestamp: '10/15/2024 4:18'
    }, {
        name: 'David E.',
        images: [],
        rating: 5,
        review: "First time cold plunger here. Went all in but I basically just use the inflatable tub. I haven't tried the adapter yet but it's been keeping cool in Florida so it's legit!",
        purchased: 'AZ-Complete',
        timestamp: '10/11/2024 9:24'
    }, {
        name: 'Luis D.',
        images: [],
        rating: 5,
        review: 'Shipped fast, cools fast, responds fast, keep up the good work!',
        purchased: 'AZ-Inflate',
        timestamp: '10/5/2024 9:48'
    }, {
        name: 'Amar E.',
        images: [],
        rating: 5,
        review: "Adapter is solid, super thick metal, locks down tight. What I will say is it's a pain to break down and move if you don't want to leave it setup. Gotta drain it into the bathtub but the thing is heavy. Would be cool to add some shut-offs to make this part easier, especially for changing filters. All in all been great when it is setup.",
        purchased: 'AZ-Adapt',
        timestamp: '8/30/2024 7:25'
    }, {
        name: 'Jack B.',
        images: [],
        rating: 5,
        review: "Got the AZ-Inflate in the lead up to my triathlon, been using it almost daily. Mostly good, I'd just say it can need pretty frequent water changes, I do every month or so. Soreness-wise it's noticeable for sure but not sure I'd say it's life changing. For the money I think this is definitely a good option, it does what it's supposed to.",
        purchased: 'AZ-Inflate',
        timestamp: '9/7/2024 17:50'
    }, {
        name: 'Sophia T.',
        images: [],
        rating: 5,
        review: "Adapter is a bit clunky, but very solid and once it is attached right it really stays well. Customer service is a 10/10, Ethan was responsive and generous with the warranty when I ran into some issues.",
        purchased: 'AZ-Adapt',
        timestamp: '9/1/2024 22:03'
    }, {
        name: 'Will M.',
        images: [],
        rating: 5,
        review: "I combine the AZ-Inflate next to my sauna. I try to go back and forth between the two until I'm ready to pass out.",
        purchased: 'AZ-Complete',
        timestamp: '10/4/2024 3:11'
    }, {
        name: 'Joseph J.',
        images: [],
        rating: 5,
        review: "I wasn't sure if the inflatable tub would be sturdy enough, but it's surprisingly tough. The chiller is clearly built pretty well, but it is heavy. Once it's setup it's not a problem but dragging it around can be a PIA.",
        purchased: 'AZ-Inflate',
        timestamp: '9/27/2024 23:39'
    }, {
        name: 'Felix O.',
        images: [],
        rating: 4,
        review: "Solid design, works just as expected. It's a bit louder than I thought it would be but not too bad.",
        purchased: 'AZ-Adapt',
        timestamp: '9/3/2024 5:13'
    }, {
        name: 'Patrick J.',
        images: [],
        rating: 5,
        review: 'Great product, although the hoses could be a bit longer.',
        purchased: 'AZ-Inflate',
        timestamp: '9/16/2024 20:40'
    }, {
        name: 'Natalie Y.',
        images: [],
        rating: 5,
        review: "Wasn't sure about it at first, but now I'm in there every day. Setup was tough I thought, tape more than you think for the connections! Once up and running it's been great.",
        purchased: 'AZ-Adapt',
        timestamp: '10/10/2024 4:24'
    }, {
        name: 'Mason J.',
        images: [],
        rating: 5,
        review: 'Overall, great product.',
        purchased: 'AZ-Inflate',
        timestamp: '9/25/2024 13:42'
    }, {
        name: 'Chloe C.',
        images: [],
        rating: 5,
        review: 'Not sure you could find a better option for the price, definitely happy with it!',
        purchased: 'AZ-Complete',
        timestamp: '9/21/2024 3:44'
    }, {
        name: 'John G.',
        images: [],
        rating: 5,
        review: 'I had an issue with the shipping being delayed, but the customer service was really responsive. The product itself is amazing, been plunging 3 times a week now.',
        purchased: 'AZ-Inflate',
        timestamp: '10/16/2024 15:02'
    }, {
        name: 'Ben B.',
        images: [],
        rating: 5,
        review: "Fantastic, fast cooling. A little louder than I'd like but I just shut the door and it's fine.",
        purchased: 'AZ-Adapt',
        timestamp: '9/17/2024 3:37'
    }, {
        name: 'Quinn F.',
        images: [],
        rating: 4,
        review: "The chiller throws a lot of heat. The garage where I have mine setup can get kind of hot, had to put a fan in the window. That being said it cools super fast. My setup goes from like 75 to 45 in two hours.",
        purchased: 'AZ-Inflate',
        timestamp: '9/10/2024 22:08'
    }, {
        name: 'Oliver J.',
        images: [],
        rating: 5,
        review: '',
        purchased: 'AZ-Adapt',
        timestamp: '9/21/2024 0:10'
    }, {
        name: 'Omar R.',
        images: [],
        rating: 5,
        review: "Does exactly what it's supposed to do. Stays cold and stays clean, I'm loving it!!",
        purchased: 'AZ-Complete',
        timestamp: '9/5/2024 8:28'
    }, {
        name: 'Mitch G.',
        images: [],
        rating: 4,
        review: "Finally caved on the hype. I did way too much research before going with AZ. Seems like there is just no way to know, every company seems to have people who love them and people who hate them. If you're out there obsessing over which to choose like me, you probably can't go wrong honestly, but AZ has been very good for the money. I think a solid tub would be cool eventually, but for the 2k range the inflatable is solid.",
        purchased: 'AZ-Inflate',
        timestamp: '10/14/2024 1:08'
    }, {
        name: 'Cameron K.',
        images: [],
        rating: 5,
        review: 'Setup was super straightforward, I had cold water within 2 hours.',
        purchased: 'AZ-Inflate',
        timestamp: '10/3/2024 16:31'
    }, {
        name: 'James C.',
        images: [],
        rating: 5,
        review: 'Works great! I leave mine running 24/7 with no issues so far.',
        purchased: 'AZ-Adapt',
        timestamp: '9/21/2024 2:22'
    }, {
        name: 'John Y.',
        images: [],
        rating: 5,
        review: 'Supportive customer service, pretty good product, filtration is a pain.',
        purchased: 'AZ-Adapt',
        timestamp: '8/25/2024 14:06'
    }, {
        name: 'Tyler G.',
        images: [],
        rating: 5,
        review: "Does what it's supposed to do!",
        purchased: 'AZ-Adapt',
        timestamp: '9/26/2024 23:34'
    }, {
        name: 'Ian E.',
        images: [],
        rating: 5,
        review: 'Great!',
        purchased: 'AZ-Adapt',
        timestamp: '10/19/2024 2:23'
    }, {
        name: 'Rain R.',
        images: [],
        rating: 5,
        review: 'Had an issue with the chiller initially, but the support team was fantastic and got me sorted out quickly.',
        purchased: 'AZ-Inflate',
        timestamp: '10/11/2024 1:29'
    }, {
        name: 'Steven K.',
        images: [],
        rating: 5,
        review: 'Shipping was fast and setup was easy enough to follow. Chills water down quickly and holds temp well.',
        purchased: 'AZ-Inflate',
        timestamp: '9/15/2024 11:17'
    }, {
        name: 'Milton B.',
        images: [],
        rating: 5,
        review: "Took a bit longer to set up than I expected, but once it's up, it's so convenient. Cold water anytime, no more ice!",
        purchased: 'AZ-Complete',
        timestamp: '9/15/2024 3:44'
    }, {
        name: 'Mary M.',
        images: [],
        rating: 5,
        review: "Cold plunging in the morning has become my favorite way to start the day. AZ-Adapt makes it so much easier, even once it's cold I'd rather just do it in my tub honestly.",
        purchased: 'AZ-Complete',
        timestamp: '10/11/2024 18:21'
    }, {
        name: 'Scott Y.',
        images: [],
        rating: 5,
        review: 'The AZ-Complete bundle is fantastic. Everything works together perfectly, and the setup was quick.',
        purchased: 'AZ-Inflate',
        timestamp: '9/12/2024 11:56'
    }, {
        name: 'Jesse Q.',
        images: [],
        rating: 5,
        review: "AZ-Complete kit is fantastic. Everything feels pretty high quality even though it's priced on the lower end.",
        purchased: 'AZ-Inflate',
        timestamp: '8/28/2024 13:14'
    }, {
        name: 'Jasmine L.',
        images: [],
        rating: 5,
        review: "DO IT!! IT'S WORTH IT!! Just got out and decided to leave this review cause I'm FIRED UP!!",
        purchased: 'AZ-Complete',
        timestamp: '9/30/2024 6:56'
    }, {
        name: 'Peter O.',
        images: [],
        rating: 5,
        review: 'Totally worth the price. The cold plunge helps my focus and energy throughout the day.',
        purchased: 'AZ-Inflate',
        timestamp: '9/1/2024 4:25'
    }, {
        name: 'Josh R.',
        images: [],
        rating: 5,
        review: 'Really pleased with this product',
        purchased: 'AZ-Adapt',
        timestamp: '9/25/2024 13:01'
    }, {
        name: 'Mercy R.',
        images: [],
        rating: 4,
        review: 'Very cold',
        purchased: 'AZ-Adapt',
        timestamp: '10/6/2024 12:11'
    }, {
        name: 'Shane F.',
        images: [],
        rating: 5,
        review: '',
        purchased: 'AZ-Complete',
        timestamp: '9/17/2024 10:04'
    }, {
        name: 'Alyssa N.',
        images: [],
        rating: 5,
        review: "I love that I can use my own bathtub for this. Saves so much space compared to buying a whole plunge setup.",
        purchased: 'AZ-Adapt',
        timestamp: '9/9/2024 10:31'
    }
]
