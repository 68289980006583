/* eslint-disable no-undef */
import { AAva, CAva, FAva, TAva } from "./StockComponents/ProductIcons";
import { AdapterPop } from "./home/AdapterPop";
import { ChillerPop } from "./home/ChillerPop";
import { TubPop } from "./home/TubPop";
import { reviewOverview } from "./shop/ReviewData";

export const productsArray = [
  // AZ-Adapt (2 variants)
  {
    id: 0,
    category: "AZ-Adapt",
    variants: ["chiller"],
    title: {
      standard: "AZ-Adapt",
      pro: "AZ-Adapt Pro",
    },
    idStripe: {
      standard: process.env.REACT_APP_PRICE_ID_0,
      pro: process.env.REACT_APP_PRICE_ID_1,
    },
    text: "Any Tub - Anywhere",
    productDescription:
      "Meet the AZ-Adapt bundle, the first-ever cold plunge system for your home bathtub. Built from durable 8-gauge aluminum, the adapter extends up to 7 inches to fit almost any tub. Our powerful 0.8hp chiller cools water by 4-8 degrees per hour, reaching as low as 37°F. Versatile and DIY-friendly, use AZ-Adapt with a stock tank, chest freezer, cooler, or any container you have. ",
    numberOfReviews: reviewOverview["AZ-Adapt"].reviewCount,
    rating: reviewOverview["AZ-Adapt"].averageRating,
    basePrice: 2100,
    proPrice: 2400,
    price: {
      standard: 2100,
      pro: 2400,
    },
    noSalePrice: {
      standard: 2600,
      pro: 2900,
    },
    includes: {
      standard: [
        { src: "/images/productImagesT/chillerT01-xs.webp", title: "Chiller" },
        { src: "/images/productImagesT/adapterT01-xs.webp", title: "Adapter" },
      ],
      pro: [
        {
          src: "/images/productImagesT/chillerProT01-xs.webp",
          title: "Chiller",
        },
        { src: "/images/productImagesT/adapterT01-xs.webp", title: "Adapter" },
      ],
    },
    productImages: {
      standard: [
        "/images/productImagesT/adaptT01",
        "/images/productImagesT/adaptT02",
        "/images/productImagesT/adaptT03",
        "/images/productImages/AZ-Adapt04",
        "/images/productImages/AZ-Adapt05",
        "/images/productImages/AZ-Adapt07",
      ],
      pro: [
        "/images/productImagesT/adaptProT01",
        "/images/productImagesT/adaptProT02",
        "/images/productImagesT/adaptProT03",
        "/images/productImages/AZ-Adapt04",
        "/images/productImages/AZ-Adapt05",
        "/images/productImages/AZ-Adapt07",
      ],
    },
  },
  // AZ-Inflate (4 variants)
  {
    id: 1,
    category: "AZ-Inflate",
    variants: ["chiller", "tub"],
    idStripe: {
      standard: {
        barrel: process.env.REACT_APP_PRICE_ID_2,
        recline: process.env.REACT_APP_PRICE_ID_3,
      },
      pro: {
        barrel: process.env.REACT_APP_PRICE_ID_4,
        recline: process.env.REACT_APP_PRICE_ID_5,
      },
    },
    title: {
      standard: {
        barrel: "AZ-Inflate Barrel",
        recline: "AZ-Inflate Recline",
      },
      pro: {
        barrel: "AZ-Inflate Barrel Pro",
        recline: "AZ-Inflate Recline Pro",
      },
    },
    text: "Dedicated Cold Plunge",
    productDescription:
      "Introducing the AZ-Inflate bundle. Set up a dedicated cold plunge in minutes. Built from PVC drop stitch the inflatable tub ensures unmatched strength and stability.  The AZ-Air bundle includes our powerful .8hp chiller, cooling the water 4-8 degrees per hour reaching temperatures as low as 37°F. Stay consistent with a dedicated plunging setup, right at home.",
    numberOfReviews: reviewOverview["AZ-Inflate"].reviewCount,
    rating: reviewOverview["AZ-Inflate"].averageRating,
    basePrice: 2500,
    proPrice: 2800,
    price: {
      standard: {
        barrel: 2500,
        recline: 2500,
      },
      pro: {
        barrel: 2800,
        recline: 2800,
      },
    },
    noSalePrice: {
      standard: {
        barrel: 3000,
        recline: 3000,
      },
      pro: {
        barrel: 3300,
        recline: 3300,
      },
    },
    includes: {
      standard: {
        barrel: [
          { src: "/images/productImagesT/tubT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerT01-xs.webp",
            title: "Chiller",
          },
        ],
        recline: [
          { src: "/images/productImagesT/tubWT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerT01-xs.webp",
            title: "Chiller",
          },
        ],
      },
      pro: {
        barrel: [
          { src: "/images/productImagesT/tubT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerProT01-xs.webp",
            title: "Chiller",
          },
        ],
        recline: [
          { src: "/images/productImagesT/tubWT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerProT01-xs.webp",
            title: "Chiller",
          },
        ],
      },
    },
    productImages: {
      standard: {
        barrel: [
          "/images/productImagesT/inflateT01",
          "/images/productImagesT/inflateT02",
          "/images/productImagesT/inflateT03",
          "/images/productImages/AZ-Inflate03",
          "/images/productImages/AZ-Inflate05",
          "/images/productImages/AZ-Inflate07",
        ],
        recline: [
          "/images/productImagesT/inflateWT01",
          "/images/productImagesT/inflateWT02",
          "/images/productImagesT/inflateWT03",
          "/images/productImages/AZ-Inflate03",
          "/images/productImages/AZ-Inflate05",
          "/images/productImages/AZ-Inflate07",
        ],
      },
      pro: {
        barrel: [
          "/images/productImagesT/inflateProT01",
          "/images/productImagesT/inflateProT02",
          "/images/productImagesT/inflateProT03",
          "/images/productImages/AZ-Inflate03",
          "/images/productImages/AZ-Inflate05",
          "/images/productImages/AZ-Inflate07",
        ],
        recline: [
          "/images/productImagesT/inflateProWT01",
          "/images/productImagesT/inflateProWT02",
          "/images/productImagesT/inflateProWT03",
          "/images/productImages/AZ-Inflate03",
          "/images/productImages/AZ-Inflate05",
          "/images/productImages/AZ-Inflate07",
        ],
      },
    },
  },
  // AZ-Complete (4 variants)
  {
    id: 2,
    category: "AZ-Complete",
    variants: ["chiller", "tub"],
    idStripe: {
      standard: {
        barrel: process.env.REACT_APP_PRICE_ID_6,
        recline: process.env.REACT_APP_PRICE_ID_7,
      },
      pro: {
        barrel: process.env.REACT_APP_PRICE_ID_8,
        recline: process.env.REACT_APP_PRICE_ID_9,
      },
    },
    title: {
      standard: {
        barrel: "AZ-Complete Barrel",
        recline: "AZ-Complete Recline",
      },
      pro: {
        barrel: "AZ-Complete Barrel Pro",
        recline: "AZ-Complete Recline Pro",
      },
    },
    text: "The Ultimate Bundle",
    productDescription:
      "Take your cold-water therapy to the next level with AZ-Complete. This all-in-one bundle includes everything you need to plunge at home or on the go. Featuring a powerful 0.8hp chiller, a versatile 7-inch adapter, and a spacious 150-gallon tub, AZ-Complete equips you for the perfect plunge experience—whether in the bath or on the deck, this bundle has you covered.",
    numberOfReviews: reviewOverview["AZ-Complete"].reviewCount,
    rating: reviewOverview["AZ-Complete"].averageRating,
    basePrice: 2900,
    proPrice: 3200,
    price: {
      standard: {
        barrel: 2900,
        recline: 2900,
      },
      pro: {
        barrel: 3200,
        recline: 3200,
      },
    },
    noSalePrice: {
      standard: {
        barrel: 3400,
        recline: 3400,
      },
      pro: {
        barrel: 3700,
        recline: 3700,
      },
    },
    includes: {
      standard: {
        barrel: [
          { src: "/images/productImagesT/tubT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerT01-xs.webp",
            title: "Chiller",
          },
          {
            src: "/images/productImagesT/adapterT01-xs.webp",
            title: "Adapter",
          },
        ],
        recline: [
          { src: "/images/productImagesT/tubWT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerT01-xs.webp",
            title: "Chiller",
          },
          {
            src: "/images/productImagesT/adapterT01-xs.webp",
            title: "Adapter",
          },
        ],
      },
      pro: {
        barrel: [
          { src: "/images/productImagesT/tubT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerProT01-xs.webp",
            title: "Chiller",
          },
          {
            src: "/images/productImagesT/adapterT01-xs.webp",
            title: "Adapter",
          },
        ],
        recline: [
          { src: "/images/productImagesT/tubWT01-xs.webp", title: "Tub" },
          {
            src: "/images/productImagesT/chillerProT01-xs.webp",
            title: "Chiller",
          },
          {
            src: "/images/productImagesT/adapterT01-xs.webp",
            title: "Adapter",
          },
        ],
      },
    },
    productImages: {
      standard: {
        barrel: [
          "/images/productImagesT/completeT01",
          "/images/productImagesT/inflateT02",
          "/images/productImagesT/adaptT03",
          "/images/productImages/AZ-Inflate04",
          "/images/productImages/AZ-Adapt04",
          "/images/productImages/AZ-Adapt07",
        ],
        recline: [
          "/images/productImagesT/completeWT01",
          "/images/productImagesT/inflateWT02",
          "/images/productImagesT/adaptT03",
          "/images/productImages/AZ-Inflate04",
          "/images/productImages/AZ-Adapt04",
          "/images/productImages/AZ-Adapt07",
        ],
      },
      pro: {
        barrel: [
          "/images/productImagesT/completeProT01",
          "/images/productImagesT/inflateProT02",
          "/images/productImagesT/adaptProT03",
          "/images/productImages/AZ-Inflate04",
          "/images/productImages/AZ-Adapt04",
          "/images/productImages/AZ-Adapt07",
        ],
        recline: [
          "/images/productImagesT/completeProWT01",
          "/images/productImagesT/inflateProWT02",
          "/images/productImagesT/adaptProT03",
          "/images/productImages/AZ-Inflate04",
          "/images/productImages/AZ-Adapt04",
          "/images/productImages/AZ-Adapt07",
        ],
      },
    },
  },
  // Adapter (single variant)
  {
    id: 3,
    category: "Adapter",
    variants: [],
    idStripe: process.env.REACT_APP_PRICE_ID_10,
    title: "Adapter",
    text: "Any tub, anywhere.",
    productDescription:
      "Introducing the world's first cold plunge adapter. Adjustable up to 7 inches, the adapter turns anything into a cold plunge in minutes. Built from 8-gauge aluminum with a black anodized finish and steel faucets, the adapter is designed to fit perfectly in your bathroom while being rugged enough for on-the-go use. Convert bathtubs, stock tanks, DIY plunges, and more.",
    numberOfReviews: reviewOverview["Adapter"].reviewCount,
    rating: reviewOverview["Adapter"].averageRating,
    dialog: AdapterPop,
    price: 400,
    basePrice: 400,
    proPrice: 400,
    noSalePrice: null,
    includes: [
      { src: "/images/productImagesT/adapterT01-xs.webp", title: "Adapter" },
    ],
    productImages: [
      "images/productImagesT/adapterT01",
      "images/productImagesT/adapterT02",
      "images/productImagesT/adapterT03",
      "/images/productImages/AZ-Adapt04",
      "/images/productImages/AZ-Adapt05",
      "/images/productImages/AZ-Adapt07",
    ],
  },
  // Chiller (2 variants)
  {
    id: 4,
    category: "Chiller",
    variants: ["chiller"],
    idStripe: {
      standard: process.env.REACT_APP_PRICE_ID_11,
      pro: process.env.REACT_APP_PRICE_ID_12,
    },
    title: {
      standard: "Chiller",
      pro: "Chiller Pro",
    },
    text: "Year round cooling.",
    productDescription:
      "Meet the 0.8hp pro chiller—your powerhouse for cold water therapy. Encased in steel and powered by GMCC compressors, the same top-tier technology found in high-end chilling equipment. The chiller cools as low as 37°F, dropping temperatures by up to 8 degrees per hour starting at 70°F. Pair it with the adapter, tub, or your own DIY setup for unmatched efficiency and reliability.",
    numberOfReviews: reviewOverview["Chiller"].reviewCount,
    rating: reviewOverview["Chiller"].averageRating,
    dialog: ChillerPop,
    basePrice: 1700,
    proPrice: 2000,
    price: { standard: 1700, pro: 2000 },
    noSalePrice: { standard: 2200, pro: 2500 },
    includes: {
      standard: [
        { src: "/images/productImagesT/chillerT01-xs.webp", title: "Chiller" },
      ],
      pro: [
        {
          src: "/images/productImagesT/chillerProT01-xs.webp",
          title: "Chiller",
        },
      ],
    },
    productImages: {
      standard: [
        "/images/productImagesT/chillerT01",
        "/images/productImagesT/chillerT02",
        "/images/productImagesT/chillerT03",
        "/images/productImages/AZ-Adapt04",
        "/images/productImages/AZ-Chiller04",
        "/images/productImages/AZ-Chiller05",
      ],
      pro: [
        "/images/productImagesT/chillerProT01",
        "/images/productImagesT/chillerProT02",
        "/images/productImagesT/chillerProT03",
        "/images/productImagesT/chillerProT04",
      ],
    },
  },
  // Tub (2 variants)
  {
    id: 5,
    category: "Tub",
    variants: ["tub"],
    idStripe: {
      barrel: process.env.REACT_APP_PRICE_ID_13,
      recline: process.env.REACT_APP_PRICE_ID_14,
    },
    title: {
      barrel: "Tub Barrel",
      recline: "Tub Recline",
    },
    text: "Dedicated plunge.",
    productDescription:
      "Engineered from the same high-performance materials trusted in industry-leading paddle boards and whitewater rafts, the AZ Tub delivers lasting resilience. Featuring 3-inch thick drop-stitch walls and a triple-layered PVC bottom, it comfortably accommodates everyone. Effortlessly deflate and store the tub in the included backpack for seamless portability and storage. ",
    numberOfReviews: reviewOverview["Tub"].reviewCount,
    rating: reviewOverview["Tub"].averageRating,
    dialog: TubPop,
    basePrice: 800,
    proPrice: 800,
    price: { barrel: 800, recline: 800 },
    noSalePrice: { barrel: 1000, recline: 1000 },
    includes: {
      barrel: [{ src: "/images/productImagesT/tubT01-xs.webp", title: "Tub" }],
      recline: [
        { src: "/images/productImagesT/tubWT01-xs.webp", title: "Tub" },
      ],
    },
    productImages: {
      barrel: [
        "/images/productImagesT/tubT01",
        "/images/productImagesT/tubT02",
        "/images/productImagesT/tubT03",
        "/images/productImages/AZ-Tub04",
      ],
      recline: [
        "/images/productImagesT/tubWT01",
        "/images/productImagesT/tubWT02",
        "/images/productImagesT/tubWT03",
      ],
    },
  },
];
export function getProductData(id, chiller = "pro", tub = "barrel") {
  const productData = productsArray.find((product) => product.id === id);

  if (!productData) {
    console.log("Product data does not exist for ID:", id);
    return undefined;
  }

  // Attempt to access variations for the given chiller and tub
  const getVariation = (property) =>
    productData[property]?.[chiller]?.[tub] || // Chiller + Tub combination
    productData[property]?.[chiller] || // Only Chiller
    productData[property]?.[tub] || // Only Tub
    productData[property]; // Default (no variation)

  return {
    id: productData.id,
    idStripe: getVariation("idStripe"),
    category: productData.category,
    text: productData.text,
    productDescription: productData.productDescription,
    numberOfReviews: productData.numberOfReviews,
    rating: productData.rating,
    dialog: productData.dialog,
    title: getVariation("title"),
    price: getVariation("price"),
    noSalePrice: getVariation("noSalePrice"),
    includes: getVariation("includes"),
    productImages: getVariation("productImages"),
  };
}
