import { createContext, useState, useEffect, React } from "react";
import { getProductData, productsArray } from '../data';
import iceColdLocal from "../../hooks/IceColdLocal";




export const CartContext = createContext({
    items: [],
    getProductQuantity: () => { },
    addOneToCart: () => { },
    removeOneFromCart: () => { },
    deleteFromCart: () => { },
    getTotalCost: () => { },
    selectedItem: null,
    setSelectedItem: () => { },
    clearCart: () => { }, // Add clearCart to the context
    selectedAttributes: {},
    setSelectedAttributes: () => { }            
});

export function CartProvider({ children }) {
    const [cartProducts, setCartProducts] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    });

    // Save cartProducts to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartProducts));
    }, [cartProducts]);

    function getProductQuantity(idStripe) {
        const quantity = cartProducts.find(
            product => product.idStripe === idStripe
        )?.quantity;
        
        return quantity === undefined ? 0 : quantity;
    }

    async function addOneToCart(title, idStripe, price, image) {
        if (!idStripe || !price) {
            console.error('Missing required product information');
            return;
        }

        try {
            const quantity = getProductQuantity(idStripe);

            if (quantity === 0) { // product is not in cart
                setCartProducts([
                    ...cartProducts, 
                    { 
                        idStripe,
                        title,
                        price,
                        image,
                        quantity: 1
                    }
                ]);
                
            } else { // product is in cart
                setCartProducts(cartProducts.map(product =>
                    product.idStripe === idStripe
                        ? { ...product, quantity: product.quantity + 1 }
                        : product
                ));
               
            }
           
            iceColdLocal({
                eventNameFB: 'AddToCart', // Specify event name for FB
                eventNameGA: 'add_to_cart', // Specify event name for GA
                title: title,
                price: price,
            });
        } catch (error) {
            console.error('Error adding item to cart:', error);
        }
    }

    function removeOneFromCart(idStripe) {
        const quantity = getProductQuantity(idStripe);

        if (quantity === 1) {
            deleteFromCart(idStripe);
            
        } else {
            setCartProducts(cartProducts.map(product =>
                product.idStripe === idStripe
                    ? { ...product, quantity: product.quantity - 1 }
                    : product
            ));
            
        }
    }

    function deleteFromCart(idStripe) {
        setCartProducts(cartProducts.filter(product => 
            product.idStripe !== idStripe
        ));
   
    }

    function getTotalCost() {
        const totalCost = cartProducts.reduce((totalCost, cartItem) => {
            return totalCost + (cartItem.price * cartItem.quantity);
        }, 0);
       
        return totalCost;
    }

    // Function to clear the cart
    function clearCart() {
        setCartProducts([]); // Clear the cart state
        localStorage.removeItem('cartItems'); // Clear the cart in localStorage
      
    }

    const [selectedItem, setSelectedItem] = useState(productsArray[0]);
    const [selectedAttributes, setSelectedAttributes] = useState({chiller: 'pro', tub: 'recline'});

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        selectedItem,
        setSelectedItem,
        clearCart, // Expose clearCart in the context
        selectedAttributes,
        setSelectedAttributes
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
}

export default CartProvider;
