import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ProductsDropdown from './ProductsDropdown';
import SupportDropdown from './SupportDropdown';
import LearnDropdown from './LearnDropdown';
import { Link, useLocation } from 'react-router-dom';
import Cart from './Cart';

const linkColor = 'black';

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const location = useLocation(); // Get the current location

    React.useEffect(() => {
        // Close the menu whenever the location changes
        handleCloseNavMenu();
    }, [location.pathname]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar minheight={0} position="fixed" elevation={0} sx={{
            bgcolor: 'rgba(0, 0, 0, 0)', top: '.5rem', '.MuiToolbar-root': {
                minHeight: 0,
                pr: 0,
            }
        }}>
            <Container className='blur' sx={{ 
                width: "auto", 
                borderRadius: '28px', 
                '.MuiContainer-root': { paddingRight: '0px' }, 
                paddingRight: '0px !important' 
            }} >
                <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button component={Link} to="/" sx={{ textDecoration: 'none', order: { xs: 1, md: 0 }, mr: '1rem' }}>
                        <Typography color={'black'} fontSize={'.8rem'} sx={{ alignText: 'center' }}>
                            <span style={{ fontWeight: '700' }}>ABSOLUTE</span>ZERO
                        </Typography>
                    </Button>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="products"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ color: linkColor }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                color: 'black',
                                '.MuiPaper-root': {
                                    overflow: 'visible',
                                    display: 'flex',
                                    bgcolor: 'grey.100',
                                }
                            }}
                            MenuListProps={{
                                'aria-labelledby': 'menu-button',
                                role: 'menu',
                            }}
                        >
                            <MenuItem disableRipple>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', alignItems: 'center', justifyContent: 'left' } }}>
                                    <ProductsDropdown color={linkColor} textTransform='none' />
                                    <LearnDropdown color={linkColor} textTransform='none' />
                                    <SupportDropdown color={linkColor} textTransform='none' />
                                </Box>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: 'center', justifyContent: 'left' } }}>
                        <Button component={Link} to='/' sx={{ color: linkColor, textTransform: 'none' }}> Home</Button>
                        <ProductsDropdown url={'/products'} color={linkColor} textTransform='none' />
                        <LearnDropdown url={'/learn'} color={linkColor} textTransform='none' />
                        <SupportDropdown url={'/support'} color={linkColor} textTransform='none' />
                    </Box>
                    <Cart color={'white'} />
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
