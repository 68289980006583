import React, { useContext, useEffect, useState } from "react";
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import { IconButton, Grow, Paper, Box, useTheme, Button, Typography } from "@mui/material";
import { CartContext } from "./CartContext";
import CartProduct from "./CartProduct";
import ReactGA from 'react-ga4';
import iceColdLocal from "../../hooks/IceColdLocal";

const Cart = ({ color }) => {
    const theme = useTheme();
    const buttonTypography = theme.typography.button;
    const cart = useContext(CartContext);
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    const [hover, setHover] = useState(false);
    const [timer, setTimer] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const handleMouseEnter = () => {
        setHover(true);
        if (timer) {
            clearTimeout(timer);
        }
    };

    useEffect(() => {
        if (!isInitialLoad) {
            setHover(true);
            if (timer) {
                clearTimeout(timer);
            }
            const newTimer = setTimeout(() => setHover(false), 2000);
            setTimer(newTimer);
        } else {
            setIsInitialLoad(false);
        }
    }, [productsCount]);

    const checkout = async () => {
        try {
            const lineItems = cart.items.map(item => ({
                price: item.idStripe,
                quantity: item.quantity
            }));

            iceColdLocal({
                eventNameFB: 'InitiateCheckout',
                eventNameGA: 'begin_checkout',
                title: cart.items[0].title,
                price: cart.items[0].price
            });

            const response = await fetch("/.netlify/functions/create-checkout-session", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(lineItems)
            });

            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            if (data.url) window.location.assign(data.url);
        } catch (error) {
            console.error("Checkout error: ", error);
        }
    };

    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setHover(false)}
            sx={{ pl: { xs: 0, md: '1rem' }, order: { xs: 2, md: 0 } }}
        >
            <IconButton
                aria-label="shopping cart"
                size="large"
                sx={{
                    bgcolor: 'primary.main',
                    '&:hover': { bgcolor: 'primary.light' },
                    m: 0,
                    borderRadius: '0 28px 28px 0',
                    height: '50px'
                }}
            >
                <Box sx={{
                    display: { xs: 'none', md: 'inline' },
                    color: 'white',
                    fontSize: buttonTypography.fontSize,
                    fontFamily: buttonTypography.fontFamily,
                    fontWeight: buttonTypography.fontWeight,
                    letterSpacing: buttonTypography.letterSpacing,
                }}>
                    Cart
                </Box>
                <ShoppingBagRoundedIcon sx={{ fontSize: '1.3rem', color, ml: { xs: 0, md: '.5rem' } }} />
            </IconButton>

            <Grow in={hover}>
                <Paper
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={() => setHover(false)}
                    sx={{
                        position: 'absolute',
                        right: { xs: -30, md: 0 },
                        backgroundColor: '#f1f1f1',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: "column",
                        width: 'auto',
                        p: 2,
                        maxHeight: 'calc(var(--lvh) * 80)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            borderRadius: '4px',
                        }
                    }}
                >
                    {productsCount > 0 ? (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                {cart.items.map((cartItem, idx) => (
                                    <CartProduct key={idx} cartItem={cartItem} />
                                ))}
                            </Box>
                            <Box sx={{
                                borderTop: '1px solid rgba(0,0,0,0.1)',
                                mt: 2,
                                pt: 1,
                                px: 1
                            }}>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                    Total: ${cart.getTotalCost().toFixed(2)}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={checkout}
                                    fullWidth
                                    sx={{ mt: 1 }}
                                >
                                    Checkout
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Box>Your cart is empty.</Box>
                    )}
                </Paper>
            </Grow>
        </Box>
    );
};

export default Cart;
