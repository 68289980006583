import React, { useContext } from "react";
import { CartContext } from "./CartContext";
import { Box, Button, Typography } from "@mui/material";

function CartProduct({ cartItem }) {
    const cart = useContext(CartContext);
    const { title, idStripe, quantity, price, image } = cartItem;

    const handleRemove = (event) => {
        event.stopPropagation();
        event.preventDefault();
        cart.removeOneFromCart(idStripe);
    };

    return (
        <Box
            sx={{
                width: '100%',
               
                mb: 1,
                minWidth: { xs: '280px', md: '320px' },
                height: '80px',
                display: 'flex',
                overflow: 'hidden',
                borderRadius: '28px',
                
            }}
        >
            <Box
                component="img"
                src={`${image}-xs.webp`}
                alt={title}
                sx={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover'
                }}
            />
            
            <Box sx={{ 
                flex: '1 1 auto',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography 
                        sx={{ 
                            fontWeight: 500,
                            maxWidth: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography sx={{ fontWeight: 400, fontSize: '1rem', ml: 1 }}>
                        {price ? '$' + price : ''}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: 'center',
                        mt: 0.5
                    }}
                >
                    <Typography>
                        {quantity}x
                    </Typography>
                    <Button 
                        color="error" 
                        onClick={handleRemove}
                        sx={{ padding: '.5rem' }}
                    >
                        Remove
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default CartProduct;
